:root {
  /*
    --xirgocamPrimaryColor: #3a74be;
    --lightXirgocamPrimaryColor:#5093ca;
    --darkXirgocamPrimaryColor: #2d62a4;
    */

  --xirgoBlue: #002c5f;
  --xirgocamPrimaryColor: var(--xirgoBlue);
  --lightXirgocamPrimaryColor: #2b598e;
  --darkXirgocamPrimaryColor: #002045;

  --amazonOrange: var(--xirgocamPrimaryColor);
  --lightAmazonOrange: var(--darkXirgocamPrimaryColor);
  --darkAmazonOrange: var(--darkXirgocamPrimaryColor);
  --ion-color-primary-tint: var(--lightXirgocamPrimaryColor);
}

html {
  position: relative;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 0px;
}

.home-grid {
  height: calc(100vh - 60px);
  display: grid;
  background-color: #eee;
}

.navbar-link:hover {
  color: #ccc;
  text-decoration: none;
}

.navbar-link:active {
  color: white;
  text-decoration: none;
}

.primary-color-background {
  background-color: var(--xirgocamPrimaryColor);
  color: white;
}

.light-primary-color-background {
  background-color: var(--lightXirgocamPrimaryColor);
  color: white;
}

path {
  transition: fill 0.6s ease-in-out;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 0.9em;
  /* Vertically center the text there */
}

@media (min-width: 768px) {
  .sidenav {
    position: sticky;
    top: 62px;
    z-index: 1000;
    height: calc(100vh - 64px);
    background-color: #f7f7f7;
    border-right: 1px solid #ececec;
    padding: 16px;
  }
}

@media (max-width: 768px) {
  .sidenav {
    visibility: hidden;
    height: 0px;
    width: 0px;
    padding: 0px;
  }
}

.topnav {
  background-color: #454545;
  padding: 8px;
}

.topnav-bg {
  background-color: #454545 !important;
}

.learn-more {
  color: var(--xirgocamPrimaryColor);
  font-weight: bolder;
}

.learn-more:hover {
  color: var(--darkXirgocamPrimaryColor);
}

.clearcolfmt .col {
  width: auto;
}

.padding-16 {
  padding: 16px;
}

.sidenavitem a {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  display: block;
  text-decoration: none;
  transition: color 0.2s ease-out;
  padding: 0.7em;
}

.sidenavitem li {
  font-size: 0.85em;
  list-style: none;
  padding: 0px;
}

.sidenavitem ul li a {
  padding-top: 0em;
  padding-bottom: 0.25em;
  color: rgba(0, 0, 0, 0.65);
}

.sidenavitem ul {
  padding-left: 8px;
}

.sidenavitem a:hover {
  color: #000;
}

.sidenavitem a:active {
  color: #666;
}

.spaceline {
  border-top: 1px solid #ececec;
}

ul.spaceline {
  margin-bottom: 6px;
  padding-top: 6px;
}

code,
.code {
  color: var(--xirgocamPrimaryColor);
}

.pagetitle {
  font-size: 36px;
  font-weight: 500;
  color: #3b4151;
  font-family: sans-serif;
}

.features-boxed {
  color: #313437;
  padding-bottom: 8rem;
}

.features-boxed p {
  color: #7d8285;
}

.features-boxed h2 {
  font-weight: 300;
  margin-bottom: 40px;
  padding-top: 40px;
  color: inherit;
}

@media (max-width: 767px) {
  .features-boxed h2 {
    margin-bottom: 25px;
    padding-top: 25px;
    font-size: 24px;
  }
}

.features-boxed .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto;
}

.features-boxed .intro p {
  margin-bottom: 0;
}

.features-boxed .features {
  padding: 50px 0;
}

.features-boxed .item {
  text-align: center;
}

.features-boxed .item .box {
  text-align: center;
  padding: 30px;
  background-color: #fff;
  margin-bottom: 30px;
}

.features-boxed .item .icon {
  font-size: 60px;
  color: rgb(100, 205, 132);
  margin-top: 20px;
  margin-bottom: 35px;
}

.features-boxed .item .name {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
  margin-top: 0;
  color: inherit;
}

.features-boxed .item .description {
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.navbar-button {
  background-color: var(--xirgocamPrimaryColor);
  border-color: var(--xirgocamPrimaryColor);
  color: #ffffff;
  font-size: 0.7em;
  text-transform: uppercase;
}

.navbar-button:hover {
  background: var(--darkXirgocamPrimaryColor);
  background-color: var(--darkXirgocamPrimaryColor);
  border-color: var(--darkXirgocamPrimaryColor);
}

.navbar-button:active,
.navbar-button:focus {
  background: var(--lightXirgocamPrimaryColor) !important;
  background-color: var(--lightXirgocamPrimaryColor) !important;
  border-color: var(--lightXirgocamPrimaryColor) !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.Button__button___vS7Mv {
  border-radius: 5px;
}

.dropdown-item:active {
  background-color: var(--lightXirgocamPrimaryColor);
}

.dropdown-item.active {
  background-color: var(--xirgocamPrimaryColor);
}

button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.definition-box {
  background: #f8f9fa;
  border-radius: 5px;
  margin-top: 10px;
  padding: 0.75rem 1.25rem;
}

.definition-list {
  list-style: disc;
  margin-bottom: 0;
}

.model-name {
  /* display: inline-block; */
}

.bold-list {
  font-weight: 700;
}

.demo-portal-frame {
  height: calc(100vh - 60px);
  width: 100%;
  border: none;
  outline: none;
  margin-bottom: 0px;
  display: block;
}

.swagger-ui h3 {
  font-weight: 700;
  font-size: 16px;
}

.swagger-ui h4 {
  font-weight: 700;
  font-size: 14px;
}

.swagger-ui p {
  font-size: 12px !important;
  font-weight: 500;
}

.swagger-ui li {
  font-size: 12px !important;
  font-weight: 500;
}
